body {
  margin: 0;
  padding: 0;
  font-family: "Avenir Next";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.outer_div {
  text-align:center;
  position:absolute;
  left:50%;
  top:50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.inner_div{
  display:inline-block;
  width:100%;
}

h2 {
  color: black;
  font-weight: bold;
  font-size: 50px;
  margin-top: 0px;
  margin-bottom: 0px;
}

h3 {
  color: black;
  font-weight: normal;
  font-size: 30px;
  margin-top: 0px;
  margin-bottom: 7px;
}

a {
  text-decoration: none;
}
